let swiper = new Swiper('.testimonial_swiper-container', {
    navigation: {
      nextEl: '.swiper-button-next-custom',
      prevEl: '.swiper-button-prev-custom',
    },
    rewind: true,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    },
    slideToClickedSlide: true,
    keyboard: {
      enabled: true,
      onlyInViewport: false
    }
  });

//   breakpoint where swiper will be destroyed
// and switches to a dual-column layout
const breakpoint = window.matchMedia( '(min-width:49rem)' );
// keep track of swiper instances to destroy later
let care_swiper;

  
  const breakpointChecker = function() {
    // if larger viewport and multi-row layout needed
    if ( breakpoint.matches === true ) {
       // clean up old instances and inline styles when available
       if ( care_swiper !== undefined ) care_swiper.destroy( true, true );
       // or/and do nothing
       return;
    // else if a small viewport and single column layout needed
    } else if ( breakpoint.matches === false ) {
       // fire small viewport version of swiper
       return enableSwiper();
    }
 };

  const enableSwiper = function() {
  care_swiper = new Swiper('.care-cards_swiper-container', {
    slidesPerView: 2,
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper-button-next-custom',
      prevEl: '.swiper-button-prev-custom',
    },
    loopFillGroupWithBlank: true,
    breakpoints: {
      640: {
        slidesPerView: 3,
        spaceBetween: 0
      },
    },
    rewind: true,
    slideToClickedSlide: true,
    keyboard: {
      enabled: true,
      onlyInViewport: false
    }
  });
};

// keep an eye on viewport size changes
breakpoint.addListener(breakpointChecker);
// kickstart
breakpointChecker();

